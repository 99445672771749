






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  toRefs,
  reactive,
  PropType,
  computed,
  defineComponent,
  onBeforeUnmount,
  ref,
  watchEffect,
  watch,
  Ref
} from '@vue/composition-api';
import { ObjectId } from 'bson';
import _, { functions } from 'lodash';
import moment from 'moment';
import calculateProgramCompletion from '@/utils/calculateProgramCompletion';
import { ACTIVITIES } from '@/constants/monitor';
import { useDbGetters, useUserActions } from '@/store';
import axios from 'axios';

export default defineComponent({
  name: 'Bar',
  components: {
    // Remove rangePicker from here
  },
  props: {
    value: {
      type: Object as PropType<Record<string, any>>,
      default: { adks: [] } as Record<string, any>
    },
    students: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>,
      default: () => []
    },
    programs: {
      required: true,
      type: Array as PropType<Array<Record<string, any>>>,
      default: () => []
    },
    errorCode: {
      required: true,
      type: Number
    },
    studentsLength: {
      required: false,
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disableLoadMore: {
      type: Boolean,
      default: false
    },
    loadData: {
      type: Function,
      default: () => {}
    },
    allStudentsFetched: {
      type: Boolean,
      default: false
    },
    isFiltering: {
      type: Boolean,
      default: false
    }
  },
  setup(props, ctx) {
    const yearRangeList = [
      '2026-2027',
      '2025-2026',
      '2024-2025',
      '2023-2024',
      '2022-2023',
      '2021-2022',
      '2020-2021'
    ];

    const dateRange = ref([]) as Ref<string[]>;
    const selectedPreset = ref('');
    const presetRanges = ref([
      { text: 'All Time', value: 'all' },
      { text: 'Last 7 Days', value: 7 },
      { text: 'Last 14 Days', value: 14 },
      { text: 'Last 30 Days', value: 30 },
      { text: 'Last 60 Days', value: 60 },
      { text: 'Last 90 Days', value: 90 },
      { text: 'Current School Year', value: 'current' },
      { text: 'Previous School Years', value: 'previous' },
      { text: 'Custom Date Range', value: 'custom' }
    ]);

    const activePreset = ref('');
    const startMenu = ref(false);
    const endMenu = ref(false);
    const showSchoolYears = ref(false);

    const state = reactive({
      activator: null,
      attach: null,
      expand: window.innerWidth >= 760,
      makeOffer: false,
      sortByStudentData: 'Completion',
      colors: ['blue'],
      editing: null,
      activityFilter: '',
      editingIndex: -1,
      sortitems: [
        { title: 'Name' },
        { title: 'First Name' },
        { title: 'Last Name' },
        { title: 'School' },
        { title: 'Team' },
        { title: 'Grade' },
        { title: 'Completion' }
      ],
      programFilter: [
        {
          text: 'All Programs',
          color: 'blue'
        }
      ],
      programFilterItems: [
        {
          text: 'All Programs',
          color: 'blue'
        }
      ],
      organizerProgram: null as Record<string, any> | null, // * used only for Organizer view of Monitor
      search: null,
      unlockInterViewKey: 1,
      intervalId: 0
    });

    const { getProgram } = useUserActions(['getProgram']);
    const filters = ['Cycle', 'Year', 'Custom'];
    const studentFilter = ref('');
    const currentInternFilter = ref({
      name: 'Current Students',
      value: 'current_students'
    });
    const internFilters = ref([
      { name: 'Current Students', value: 'current_students' },
      { name: 'Current Interns', value: 'current_interns' },
      { name: 'Past Interns', value: 'past_interns' }
    ]);
    const selectedYear = ref('');
    const selectedRange = ref('');
    const filterDialog = ref(false);
    const monitorType = ref('');
    const studentList = ref([]);
    const localStoredVal = ref(null);
    const isSortingFilter = ref(false);
    const isActivityFilter = ref(false);
    const isProgramFilter = ref(false);
    const isCurrentInternFilter = ref(false);
    const watchInterviewDialog = ref(false);
    const ansUrl = ref('');
    const terminationReason = ref('');
    const isTerminating = ref(false);
    const terminationDialog = ref(false);

    const selectedStudent = computed({
      get: () => props.value,
      set: newValue => {
        ctx.emit('input', newValue);
      }
    });

    function openInterviewDialog(val) {
      watchInterviewDialog.value = true;
      ansUrl.value = val;
    }

    watchEffect(() => {
      if (!watchInterviewDialog.value) {
        ansUrl.value = '';
      }
    });

    const yearList = computed(() => {
      const rangeOfYears = (start, end) =>
        Array(end - start + 1)
          .fill(start)
          .map((year, index) => year + index);
      return rangeOfYears(2020, moment().year());
    });

    const isValidDate = computed(() => {
      if (activePreset.value === 'custom') {
        return (
          dateRange.value &&
          dateRange.value.length === 2 &&
          dateRange.value[0] &&
          dateRange.value[1]
        );
      }
      return true;
    });

    const setDateRange = (range: string[]) => {
      if (range && range.length === 2) {
        dateRange.value = range;
        selectedRange.value = ''; // Clear any selected school year range
        studentFilter.value = 'Custom';
      }
    };

    const turnOn = ref(1);
    const programToMonitor = computed(() =>
      ctx.root.$route.query ? ctx.root.$route.query.program : null
    );

    // if programId provided in query, must be Organzier view. Set filter to the program
    if (programToMonitor.value) {
      (async () => {
        let program = props.programs.find(obj => obj._id.toString() === programToMonitor.value)!;
        // Edge case (program has no participants): query for this specfic program
        if (!program) {
          program = await getProgram({
            program_id: ctx.root.$route.query.program
          });
          ctx.emit('input', null);
        }
        state.organizerProgram = program;
        state.programFilter = [
          {
            text: program.programName,
            color: 'blue'
          }
        ];
      })();
    }

    async function loadPrograms() {
      props.programs
        ?.map(doc => doc.programName)
        .forEach((name: string) => {
          state.programFilterItems.push({
            text: name,
            color: _.sample(state.colors) as string
          });
        });
    }

    watch(props, val => {
      if (val.programs) {
        loadPrograms();
      }
    });

    const sortAlphabetically = (a, b, key) => {
      if (key === 'firstName') {
        const textA = a.name.split(' ')[0].toUpperCase();
        const textB = b.name.split(' ')[0].toUpperCase();
        if (textA < textB) return -1;
        if (textA > textB) return 1;
        return 0;
      }

      if (key === 'lastName') {
        const textA = a.name.split(' ').slice(-1)[0].toUpperCase();
        const textB = b.name.split(' ').slice(-1)[0].toUpperCase();
        if (textA < textB) return -1;
        if (textA > textB) return 1;
        return 0;
      }

      const textA = key === 'school' ? a.school.name?.toUpperCase() : a[key]?.toUpperCase();
      const textB = key === 'school' ? b.school.name?.toUpperCase() : b[key]?.toUpperCase();
      if (textA < textB) return -1;
      if (textA > textB) return 1;
      return 0;
    };

    const filteredStudents = computed(() => {
      return props.students;
    });

    const filterbyDateRange = computed(() => {
      const students: any = [];
      for (let j = 0; j < filteredStudents?.value?.length; j++) {
        const element = filteredStudents.value[j];
        const d = moment(element.created).format('YYYY-MM-DD');
        element.created = d;
        students.push(element);
      }
      return students;
    });

    watchEffect(() => {
      if (studentFilter.value !== 'Custom') {
        dateRange.value = [];
      }
      if (studentFilter.value !== 'Year') {
        selectedYear.value = '';
      }
      if (studentFilter.value !== 'Cycle') {
        selectedRange.value = '';
      }
    });

    function getFilter() {
      localStoredVal.value = ctx.root.$route.query.program
        ? JSON.parse(localStorage.getItem(`setFilter_${ctx.root.$route.query.program}`))
        : JSON.parse(localStorage.getItem(`setFilter_General`));

      if (localStoredVal.value) {
        const preference: any = localStoredVal.value;
        studentFilter.value = preference.type;
        selectedPreset.value = preference.preset || '';

        if (preference.type === 'Cycle') {
          selectedRange.value = preference.filterValue;
        } else if (studentFilter.value === 'Year') {
          selectedYear.value = preference.filterValue;
        } else {
          dateRange.value = preference.filterValue;
        }
      }
    }

    let setFilterVal;
    let setInternFilterVal;
    let setActivityFilterVal;
    let setProgramFilterVal;
    if (ctx.root.$route.query.program) {
      monitorType.value = 'Student';
      setFilterVal = localStorage.getItem(`setSortingFilter_${ctx.root.$route.query.program}`);
      if (setFilterVal) {
        state.sortByStudentData = setFilterVal;
        isSortingFilter.value = true;
      }

      setInternFilterVal = localStorage.getItem(`setInternFilter_${ctx.root.$route.query.program}`);
      if (setInternFilterVal) {
        currentInternFilter.value = JSON.parse(setInternFilterVal);
        isCurrentInternFilter.value = true;
      }

      setActivityFilterVal = localStorage.getItem(
        `setActivityFilter_${ctx.root.$route.query.program}`
      );
      if (setActivityFilterVal) {
        state.activityFilter = setActivityFilterVal;
        ctx.emit('update:activityFilter', state.activityFilter);
        isActivityFilter.value = true;
      }

      setProgramFilterVal = localStorage.getItem(
        `setProgramFilter_${ctx.root.$route.query.program}`
      );
      if (setProgramFilterVal) {
        state.programFilter = JSON.parse(setProgramFilterVal);
        isProgramFilter.value = true;
      }
      getFilter();
    } else {
      monitorType.value = 'General';
      setFilterVal = localStorage.getItem(`setSortingFilter_General`);
      if (setFilterVal) {
        state.sortByStudentData = setFilterVal;
        isSortingFilter.value = true;
      }

      setInternFilterVal = localStorage.getItem(`setInternFilter_General`);
      if (setInternFilterVal) {
        currentInternFilter.value = JSON.parse(setInternFilterVal);
        isCurrentInternFilter.value = true;
      }

      setActivityFilterVal = localStorage.getItem(`setActivityFilter_General`);
      if (setActivityFilterVal) {
        state.activityFilter = setActivityFilterVal;
        ctx.emit('update:activityFilter', state.activityFilter);
        isActivityFilter.value = true;
      }

      setProgramFilterVal = localStorage.getItem(`setProgramFilter_General`);
      if (setProgramFilterVal) {
        state.programFilter = JSON.parse(setProgramFilterVal);
        isProgramFilter.value = true;
      }
      getFilter();
    }

    function dateFilteredStudents() {
      const f = filterbyDateRange.value;

      if (!studentFilter.value || studentFilter.value === 'All') {
        // Show all participants when no filter is applied or "All" is selected
        studentList.value = f;
      } else if (studentFilter.value === 'Custom' && dateRange.value?.length === 2) {
        studentList.value = f.filter(
          d => d.created >= dateRange.value[0] && d.created <= dateRange.value[1]
        );
      }

      if (state.sortByStudentData === 'Name') {
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => sortAlphabetically(a, b, 'name'));
      }

      if (state.sortByStudentData === 'First Name') {
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => sortAlphabetically(a, b, 'firstName'));
      }

      if (state.sortByStudentData === 'Last Name') {
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => sortAlphabetically(a, b, 'lastName'));
      }

      if (state.sortByStudentData === 'Team')
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => sortAlphabetically(a, b, 'teamName'));
      if (state.sortByStudentData === 'School')
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => sortAlphabetically(a, b, 'school'));
      if (state.sortByStudentData === 'Grade')
        studentList.value = studentList.value
          .slice()
          .sort((a, b) => parseInt(a.grade, 10) - parseInt(b.grade, 10));
      if (state.sortByStudentData === 'Completion') {
        studentList.value = studentList.value.slice().sort((a, b) => {
          return b?.adksCompletion - a?.adksCompletion;
        });
      }

      getFilter();

      // Emit the filtered students list up to the parent
      ctx.emit('update:filteredStudents', studentList.value);
    }

    watch(filterbyDateRange, newVal => {
      dateFilteredStudents();
    });
    dateFilteredStudents();

    function setFilter(type) {
      if (type === 'filterByTime') {
        let filterValue;

        if (studentFilter.value === 'All') {
          filterValue = 'All Time';
        } else if (studentFilter.value === 'Custom') {
          filterValue = dateRange.value;
        } else {
          filterValue = selectedRange.value;
        }

        const filter = {
          type: studentFilter.value,
          filterValue,
          preset: activePreset.value
        };

        if (monitorType.value === 'Student') {
          localStorage.setItem(
            `setFilter_${ctx.root.$route.query.program}`,
            JSON.stringify(filter)
          );
        } else {
          localStorage.setItem(`setFilter_General`, JSON.stringify(filter));
        }
        dateFilteredStudents();
        filterDialog.value = false;
      } else if (type === 'sortByStudentData' && state.sortByStudentData !== 'Completion') {
        if (monitorType.value === 'Student') {
          localStorage.setItem(
            `setSortingFilter_${ctx.root.$route.query.program}`,
            state.sortByStudentData
          );
        } else {
          localStorage.setItem(`setSortingFilter_General`, state.sortByStudentData);
        }
        isSortingFilter.value = true;
      } else if (
        type === 'currentInternFilter' &&
        currentInternFilter?.value?.value !== 'current_students'
      ) {
        if (monitorType.value === 'Student') {
          localStorage.setItem(
            `setInternFilter_${ctx.root.$route.query.program}`,
            JSON.stringify(currentInternFilter.value)
          );
        } else {
          localStorage.setItem(
            `setInternFilter_General`,
            JSON.stringify(currentInternFilter.value)
          );
        }
        isCurrentInternFilter.value = true;
      } else if (type === 'activityFilter' && state.activityFilter !== '') {
        if (monitorType.value === 'Student') {
          localStorage.setItem(
            `setActivityFilter_${ctx.root.$route.query.program}`,
            state.activityFilter
          );
        } else {
          localStorage.setItem(`setActivityFilter_General`, state.activityFilter);
        }
        ctx.emit('update:activityFilter', state.activityFilter);
        isActivityFilter.value = true;
      } else if (
        type === 'programFilter' &&
        !(
          state?.programFilter?.length === 1 &&
          state?.programFilter?.find(obj => obj?.text === 'All Programs')
        )
      ) {
        if (monitorType.value === 'Student') {
          localStorage.setItem(
            `setProgramFilter_${ctx.root.$route.query.program}`,
            JSON.stringify(state.programFilter)
          );
        } else {
          localStorage.setItem(`setProgramFilter_General`, JSON.stringify(state.programFilter));
        }
        isProgramFilter.value = true;
      }
    }

    function removeFilter(type) {
      if (type === 'filterByTime') {
        if (monitorType.value === 'Student') {
          localStorage.removeItem(`setFilter_${ctx.root.$route.query.program}`);
        } else {
          localStorage.removeItem(`setFilter_General`);
        }

        selectedYear.value = '';
        selectedRange.value = '';
        dateRange.value = [];
        studentFilter.value = '';
        filterDialog.value = false;
        dateFilteredStudents();
      } else if (type === 'sortByStudentData') {
        if (monitorType.value === 'Student') {
          localStorage.removeItem(`setSortingFilter_${ctx.root.$route.query.program}`);
        } else {
          localStorage.removeItem(`setSortingFilter_General`);
        }
        state.sortByStudentData = 'Completion';
        isSortingFilter.value = false;
      } else if (type === 'currentInternFilter') {
        if (monitorType.value === 'Student') {
          localStorage.removeItem(`setInternFilter_${ctx.root.$route.query.program}`);
        } else {
          localStorage.removeItem(`setInternFilter_General`);
        }
        currentInternFilter.value = {
          name: 'Current Students',
          value: 'current_students'
        };
        isCurrentInternFilter.value = false;
      } else if (type === 'activityFilter') {
        if (monitorType.value === 'Student') {
          localStorage.removeItem(`setActivityFilter_${ctx.root.$route.query.program}`);
        } else {
          localStorage.removeItem(`setActivityFilter_General`);
        }
        state.activityFilter = '';
        ctx.emit('update:activityFilter', '');
        isActivityFilter.value = false;
      } else if (type === 'programFilter') {
        if (monitorType.value === 'Student') {
          localStorage.removeItem(`setProgramFilter_${ctx.root.$route.query.program}`);
        } else {
          localStorage.removeItem(`setProgramFilter_General`);
        }
        state.programFilter = [
          {
            text: 'All Programs',
            color: 'blue'
          }
        ];
        isProgramFilter.value = false;
      }
    }

    watch(
      () => state.sortByStudentData,
      val => {
        if (val === 'Completion') {
          removeFilter('sortByStudentData');
        } else {
          setFilter('sortByStudentData');
        }
      }
    );

    watch(currentInternFilter, val => {
      if (val) {
        setFilter('currentInternFilter');
        ctx.emit('clearValue');
        props.loadData(val.value);
      }
    });

    watch(
      () => state.activityFilter,
      val => {
        if (val) {
          setFilter('activityFilter');
        } else {
          removeFilter('activityFilter');
        }
      }
    );

    if (!programToMonitor.value) {
      watch(
        () => state.programFilter,
        val => {
          if (val?.length === 1 && val?.find(obj => obj?.text === 'All Programs')) {
            if (monitorType.value === 'Student') {
              localStorage.removeItem(`setProgramFilter_${ctx.root.$route.query.program}`);
            } else {
              localStorage.removeItem(`setProgramFilter_General`);
            }
            isProgramFilter.value = false;
          } else {
            setFilter('programFilter');
          }
        }
      );
    }

    const formatStudentGrade = grade => `${grade}th Grade`;

    const studentInfo = student => {
      const info = {
        School: student.school.name,
        Team: student.teamName,
        Grade: formatStudentGrade(student.grade)
      };
      const filtered = Object.keys(info)
        .filter(
          key =>
            key !== state.sortByStudentData &&
            state.sortByStudentData !== 'First Name' &&
            state.sortByStudentData !== 'Last Name'
        )
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: info[key]
          };
        }, {});
      return [
        ...Object.values(filtered),
        student.program,
        student.lastSaved ? student.lastSaved.toLocaleString() : 'N/A'
      ];
    };

    const handleProgramFilterInput = () => {
      if (state.programFilter.length) {
        studentList.value = props.students;
        const stds = studentList.value;
        let filters: any = [];
        filters = state.programFilter.map(p => p.text);
        if (!filters.includes('All Programs')) {
          studentList.value = stds.filter(s => filters?.includes(s.programName));
        }
      }
    };

    const edit = (index, item) => {
      if (!state.editing) {
        state.editing = item;
        state.editingIndex = index;
      } else {
        state.editing = null;
        state.editingIndex = -1;
      }
    };

    const filter = (item, queryText, itemText) => {
      if (item.header) return false;

      const hasValue = val => (val != null ? val : '');

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    };

    state.intervalId = window.setInterval(() => {
      state.unlockInterViewKey += 1;
    }, 1000);

    onBeforeUnmount(() => {
      window.clearInterval(state.intervalId);
    });

    function showMakeOfferForm(studentId: ObjectId) {
      const selectedStudent = props.students.find(student => student._id === studentId);
      if (selectedStudent) {
        selectedStudent.showMakeOfferForm = true;
        ctx.emit('input-value', selectedStudent);
      }
    }

    const showProgressBar = (sorting, student) => {
      return (
        (sorting === 'Name' ||
          sorting === 'First Name' ||
          sorting === 'Last Name' ||
          sorting === 'Completion') &&
        student.studentStatus === 'notAutoApplied'
      );
    };

    const airtableEmbed = ref(false);
    const currentProgram = ref();

    async function setCurrentProgram() {
      currentProgram.value = await getProgram({
        program_id: ctx.root.$route.query.program
      });
    }

    if (programToMonitor.value) {
      setCurrentProgram();
    }

    const airtableLink = computed(() => {
      return currentProgram?.value?.airtable_link;
    });

    async function signOffIntern() {
      try {
        isTerminating.value = true;
        const API = process.env.VUE_APP_SIGNOFF_SERVICE;
        const data = {
          type: 'terminate',
          student_id: selectedStudent.value._id.toString(),
          reason: terminationReason.value
        };
        const res = await axios.post(`${API}/signoff-intern`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        });
        if (res.data.data) {
          isTerminating.value = false;
          terminationDialog.value = false;
          selectedStudent.value.offerDetails = res.data.data?.offerDetails;
        }
      } catch (error) {
        console.log(error);
        isTerminating.value = false;
      }
    }

    const currentSchoolYear = computed(() => {
      const currentDate = moment();
      const currentYear = currentDate.year();
      const startOfSchoolYear = moment(`${currentYear}-07-01`);
      const endOfSchoolYear = moment(`${currentYear + 1}-06-30`);

      if (currentDate.isBetween(startOfSchoolYear, endOfSchoolYear, null, '[]')) {
        return `${currentYear}-${currentYear + 1}`;
      }
      return `${currentYear + 1}-${currentYear + 2}`;
    });

    selectedRange.value = currentSchoolYear.value;

    const currentView = ref('dashboard'); // Default to dashboard view

    function handleViewChange(view) {
      currentView.value = view;
      switch (view) {
        case 'dashboard':
          ctx.emit('view-change', 'dashboard');
          break;
        case 'setup':
          ctx.root.$router.push({
            name: 'guide',
            params: { programId: programToMonitor.value, page: 0 }
          });
          break;
        case 'monitor':
          ctx.root.$router.push({ name: 'Monitor' });
          break;
        default:
          ctx.emit('view-change', 'dashboard');
          break;
      }
    }

    const formatDate = (date: string) => {
      return date ? moment(date).format('MMM D, YYYY') : '';
    };

    const getFilterDisplayText = filter => {
      // Check for current school year
      if (filter.preset === 'current') {
        return `Current School Year (${currentSchoolYear.value})`;
      }

      // Check for previous school year
      if (filter.preset === 'previous') {
        const [currentStart] = currentSchoolYear.value.split('-');
        const prevYear = `${parseInt(currentStart, 10) - 1}-${currentStart}`;
        return `Previous School Year (${prevYear})`;
      }

      // Check for specific school year
      if (typeof filter.preset === 'string' && filter.preset.includes('-')) {
        return `${filter.preset} School Year`;
      }

      // Check for date range
      if (filter.filterValue.length === 2) {
        return `${moment(filter.filterValue[0]).format('MMM D, YYYY')} ~ ${moment(
          filter.filterValue[1]
        ).format('MMM D, YYYY')}`;
      }

      // Default case
      return filter.filterValue;
    };

    const handlePresetChange = (value: string | number) => {
      if (value === 'current') {
        const year = currentSchoolYear.value;
        const [startYear, endYear] = year.split('-');
        dateRange.value = [`${startYear}-07-01`, `${endYear}-06-30`];
        selectedRange.value = year;
      } else if (typeof value === 'string' && value.includes('-')) {
        const [startYear, endYear] = value.split('-');
        dateRange.value = [`${startYear}-07-01`, `${endYear}-06-30`];
        selectedRange.value = value;
      } else {
        const endDate = moment().endOf('day');
        const startDate = moment()
          .subtract(value as number, 'days')
          .startOf('day');
        dateRange.value = [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')];
      }
      studentFilter.value = 'Custom';
    };

    const handlePresetOption = (value: string | number) => {
      activePreset.value = value;
      showSchoolYears.value = false;

      if (value === 'all') {
        studentFilter.value = 'All';
        dateRange.value = [];
        selectedRange.value = '';
        selectedYear.value = '';
        return;
      }
      if (value === 'custom') {
        studentFilter.value = 'Custom';
        dateRange.value = [];
        return;
      }
      if (value === 'previous') {
        showSchoolYears.value = true;
        studentFilter.value = 'Cycle';
        selectedRange.value = '';
        return;
      }

      // Handle other preset options
      handlePresetChange(value);
    };

    const handleSchoolYearSelect = (year: string) => {
      handlePresetChange(year);
      activePreset.value = 'previous';
    };

    const formatDateRange = (range: string[]) => {
      if (!range || range.length !== 2) return '';

      const startDate = moment(range[0]).format('MMM D, YYYY');
      const endDate = moment(range[1]).format('MMM D, YYYY');

      // If same year, only show year once
      if (moment(range[0]).year() === moment(range[1]).year()) {
        return `${moment(range[0]).format('MMM D')} - ${endDate}`;
      }

      return `${startDate} - ${endDate}`;
    };

    // Add some CSS for the filter button
    const style = document.createElement('style');
    style.textContent = `
      .filter-text {
        font-size: 13px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
      }
    `;
    document.head.appendChild(style);

    const searchQuery = ref('');

    // Add computed property for filtered students
    const filteredStudentList = computed(() => {
      if (!searchQuery.value) {
        return studentList.value;
      }

      const query = searchQuery.value.toLowerCase();
      return studentList.value.filter(student => {
        // Use more efficient search
        const searchableFields = [
          student.name,
          student.teamName,
          student.school?.name,
          student.program,
          student.grade?.toString()
        ].filter(Boolean);

        return searchableFields.some(field => field.toLowerCase().includes(query));
      });
    });

    // Update the existing watch or computed property that emits filtered students
    watch([filteredStudentList], () => {
      ctx.emit('update:filteredStudents', filteredStudentList.value);
    });

    const getProgressColor = value => {
      // Pure red at 0%, pure green at 100%, with 10% increments
      if (value === 100) return '#6eba80'; // Pure green for 100%
      if (value === 0) return '#ea6764'; // Pure red for 0%

      // Calculate the color mix based on 10% increments
      const percentage = Math.floor(value / 10) * 10;

      const colorMap = {
        0: '#ea6764', // Pure red
        10: '#eb7663', // Red-orange
        20: '#ec8662', // Orange-red
        30: '#ed9661', // Orange
        40: '#eea760', // Orange-yellow
        50: '#efb75f', // Yellow
        60: '#beb664', // Yellow-green
        70: '#8eb569', // Light green
        80: '#7eb772', // Green
        90: '#6eb97b', // Green
        100: '#6eba80' // Pure green
      };

      return colorMap[percentage];
    };

    return {
      ...toRefs(state),
      currentInternFilter,
      internFilters,
      terminationReason,
      signOffIntern,
      terminationDialog,
      isTerminating,
      currentProgram,
      airtableLink,
      filters,
      openInterviewDialog,
      watchInterviewDialog,
      ansUrl,
      studentList,
      filterDialog,
      monitorType,
      localStoredVal,
      isSortingFilter,
      isActivityFilter,
      isProgramFilter,
      isValidDate,
      getFilter,
      removeFilter,
      setFilter,
      filterbyDateRange,
      dateFilteredStudents,
      selectedRange,
      setDateRange,
      selectedYear,
      yearList,
      yearRangeList,
      studentFilter,
      programToMonitor,
      filteredStudents,
      showMakeOfferForm,
      studentInfo,
      formatStudentGrade,
      calculateProgramCompletion,
      edit,
      filter,
      activities: ACTIVITIES,
      handleProgramFilterInput,
      turnOn,
      showProgressBar,
      airtableEmbed,
      selectedStudent,
      isCurrentInternFilter,
      currentView,
      handleViewChange,
      selectedPreset,
      presetRanges,
      handlePresetChange,
      startMenu,
      endMenu,
      formatDate,
      getFilterDisplayText,
      showSchoolYears,
      handlePresetOption,
      handleSchoolYearSelect,
      dateRange,
      activePreset,
      formatDateRange,
      searchQuery,
      filteredStudentList,
      getProgressColor
    };
  },
  methods: {
    openGuideBar() {
      this.expand = !this.expand;
      this.$emit('expand-change', this.expand);
    }
  }
});
